<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="70%"
    height="90%"
    @open="openDialog"
    top="7vh"
    append-to-body
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>选择老师</span>
    </div>
    <el-row :gutter="60">
      <el-col :span="14">
        <el-row>
          <el-row :gutter="20" style="margin-bottom: 20px">
            <el-col :span="16">
              <el-input
                placeholder="请输入内容"
                v-model="inputVal"
                class="input-with-select"
                @keyup.enter.native="search"
              >
                <el-select
                  v-model="select"
                  slot="prepend"
                  placeholder="请选择"
                  style="width: 100px"
                >
                  <el-option label="用户名" value="1"></el-option>
                  <el-option label="姓名" value="2"></el-option>
                </el-select>
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="search"
                ></el-button>
              </el-input>
            </el-col>
          </el-row>
          <el-table
            :data="allList"
            ref="allList"
            border
            fit
            highlight-current-row
            :row-key="getRowKeys"
            @selection-change="allhandleselectChange"
          >
            <el-table-column
              type="selection"
              width="70"
              align="center"
              :reserve-selection="true"
            ></el-table-column>
            <el-table-column
              label="用户名"
              align="center"
              prop="loginName"
            ></el-table-column>
            <el-table-column
              label="姓名"
              align="center"
              prop="name"
            ></el-table-column>
          </el-table>
          <el-pagination
            class="pagination"
            @current-change="allhandleCurrentChange"
            :current-page="allPagination.currentPage"
            :page-sizes="allPagination.pageSizes"
            :page-size="allPagination.pageSize"
            :layout="allPagination.layout"
            :total="allPagination.total"
          ></el-pagination>
        </el-row>
      </el-col>
      <el-col :span="10">
        <el-row
          style="
            box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.15);
            padding-bottom: 30px;
          "
        >
          <el-row class="seleRow">
            <span>已选择：</span>
            <span class="seleNum" v-text="multipleSelectionTotal.length"></span>
          </el-row>
          <ul
            v-for="(item, index) of multipleSelectionTotal"
            :key="index"
            class="seleList"
          >
            <li class="sele-item">
              {{ item.name }} [{{ item.loginName }}]
              <i
                class="el-icon-delete-solid ico-del"
                @click="delStu(index, item.userId)"
              ></i>
            </li>
          </ul>
        </el-row>
      </el-col>
    </el-row>
    <el-row slot="footer">
      <el-button type="primary" @click="confirm" class="dialog-btn1"
        >确 定</el-button
      >
      <el-button @click="close" type="info" class="dialog-btn1">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
import { getEmployeeList } from "@/api/member/employee";

export default {
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    seleTeaData: {
      default: () => {
        return [];
      },
      type: Array,
    },
    teacherId: {
      default: () => "",
      type: String,
    },
  },
  // watch:{
  //   seleTeaData:{
  //     handler(val){
  //       console.log();
  //     },
  //     immediate: true,
  //   }
  // },
  data() {
    return {
      // schoolArray:[],
      campusId: "",
      inputVal: "",
      select: "",
      seleTitle: "选择老师",
      waitList: [], // 待分班学员列表
      allList: [], // 全部学员
      allPagination: {
        currentPage: 1,
        pageSize: 10,
        layout: "total, prev, pager, next, jumper",
        total: 0,
      },
      multipleSelectionTotal: [], // 已选择学员
      multipleSelectionAll: [], // 全部学员
      idKey: "userId",
    };
  },
  methods: {
    openDialog() {
      this.inputVal = "";
      this.campusId = "";
      this.allPagination.currentPage = 1;
      this.multipleSelectionAll = [];
      this.multipleSelectionTotal = this.seleTeaData;
      this.chooseLoadList();
    },
    chooseLoadList() {
      this.getEmployeeList();
    },

    search() {
      this.allPagination.currentPage = 1;
      this.chooseLoadList();
    },
    getRowKeys(row) {
      return row.userId;
    },
    async getEmployeeList() {
      const res = await getEmployeeList({
        pageNum: this.allPagination.currentPage,
        pageSize: this.allPagination.pageSize,
        needCount: true,
        state: 1,
        // roleCodeSet: [2],
        loginName: this.select === "1" ? this.inputVal : "",
        name: this.select === "2" ? this.inputVal : "",
      });
      if (this.teacherId) {
        let arr = res.body.list.filter(
          (i) => i.userId == this.teacherId
        );
        this.multipleSelectionAll = arr;
        this.multipleSelectionTotal = arr;
        this.$refs.allList.clearSelection();
        this.$refs.allList.toggleRowSelection(arr[0]);
      }
      this.allList = res.body.list;
      this.allPagination.total = 18;
    },
    // 清除勾选
    clearWaitMerge(newVal, oldVal) {
      let ids = [];
      oldVal.forEach((ele) => {
        ids.push(ele.userId);
      });
      let idKey = this.idKey;
      for (let i = 0; i < this.allList.length; i++) {
        for (let j = 0; j < ids.length; j++) {
          if (ids[j].indexOf(this.allList[i][idKey]) >= 0) {
            // 设置选中，记住table组件需要使用ref="table"
            this.$refs.allList.toggleRowSelection(this.allList[i], false);
          }
        }
      }
    },

    clearAllMerge(newVal, oldVal) {
      let ids = [];
      oldVal.forEach((ele) => {
        ids.push(ele.userId);
      });
      let idKey = this.idKey;
      for (let i = 0; i < this.waitList.length; i++) {
        for (let j = 0; j < ids.length; j++) {
          if (ids[j].indexOf(this.waitList[i][idKey]) >= 0) {
            // 设置选中，记住table组件需要使用ref="table"
            this.$refs.waitList.toggleRowSelection(this.waitList[i], false);
          }
        }
      }
    },

    allhandleselectChange(val) {
      this.multipleSelectionAll = val;
      this.multipleSelectionTotal = val;
      if (val.length > 1) {
        this.$refs.allList.clearSelection();
        this.$refs.allList.toggleRowSelection(val.pop());
      }
    },

    // 关闭
    close() {
      this.$emit("close");
      this.$refs.allList.clearSelection();
    },

    // 分页
    allhandleCurrentChange(val) {
      this.allPagination.currentPage = val;
      this.getEmployeeList();
    },

    // 保存
    confirm() {
      this.$nextTick(() => {
        if (this.multipleSelectionTotal.length === 0) {
          window.$msg("请选择上课老师", 2);
        } else {
          this.$emit("teacherData", this.multipleSelectionTotal);
          this.close();
        }
      });
    },

    // 删除老师
    delStu(index, id) {
      if (
        !this.multipleSelectionTotal ||
        this.multipleSelectionTotal.length <= 0
      ) {
        return;
      }
      this.multipleSelectionTotal.splice(index, 1);
      // 标识当前行的唯一键的名称
      let idKey = this.idKey;
      let selectAllIds = id;
      for (let i = 0; i < this.allList.length; i++) {
        if (selectAllIds.indexOf(this.allList[i][idKey]) >= 0) {
          // 设置选中，记住table组件需要使用ref="table"
          this.$refs.allList.toggleRowSelection(this.allList[i], false);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
/deep/ .el-table td {
  padding: 10px 0 !important;
}
/deep/ .el-dialog {
  overflow: auto;
}
/deep/ thead {
  .el-table-column--selection {
    .cell {
      display: none;
    }
  }
}
.seleRow {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  background: #efefef;
  box-sizing: border-box;
  padding-left: 25px;
}
.seleNum {
  color: #fff;
  border-radius: 50%;
  padding: 1px 8px;
  background: #cccccc;
}
.seleList {
  box-sizing: border-box;
  margin-top: 20px;
  padding-left: 20px;
}
.ico-del {
  display: inline-block;
  margin-right: 50px;
  cursor: pointer;
}
.dialog-btn1 {
  width: 200px;
  height: 50px;
  margin-bottom: 38px;
  &:first-child {
    margin-left: 400px;
    margin-right: 38px;
  }
}
</style>
